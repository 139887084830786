/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {
    Number,
    StatisticsControllerGetPublishedStoriesCountByPeriodParams,
    GetManagerMainStatisticResponse,
    StatisticsControllerGetManagerMainStatisticParams,
    GetManagerAuthorsStatisticResponse,
    StatisticsControllerGetManagerAuthorsStatisticParams,
    GetManagerGeneralStatisticResponse
} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const statisticsControllerGetOnlineUsersCount = (signal?: AbortSignal) => {
    return axiosInstance<Number>({url: `/public/statistics/online-users-count`, method: 'get', signal});
};

export const getStatisticsControllerGetOnlineUsersCountQueryKey = () => [`/public/statistics/online-users-count`];

export type StatisticsControllerGetOnlineUsersCountQueryResult = NonNullable<Awaited<ReturnType<typeof statisticsControllerGetOnlineUsersCount>>>;
export type StatisticsControllerGetOnlineUsersCountQueryError = unknown;

export const useStatisticsControllerGetOnlineUsersCount = <
    TData = Awaited<ReturnType<typeof statisticsControllerGetOnlineUsersCount>>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof statisticsControllerGetOnlineUsersCount>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStatisticsControllerGetOnlineUsersCountQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof statisticsControllerGetOnlineUsersCount>>> = ({signal}) =>
        statisticsControllerGetOnlineUsersCount(signal);

    const query = useQuery<Awaited<ReturnType<typeof statisticsControllerGetOnlineUsersCount>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const statisticsControllerGetPublishedStoriesCount = (signal?: AbortSignal) => {
    return axiosInstance<Number>({url: `/public/statistics/published-stories-count`, method: 'get', signal});
};

export const getStatisticsControllerGetPublishedStoriesCountQueryKey = () => [`/public/statistics/published-stories-count`];

export type StatisticsControllerGetPublishedStoriesCountQueryResult = NonNullable<
    Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCount>>
>;
export type StatisticsControllerGetPublishedStoriesCountQueryError = unknown;

export const useStatisticsControllerGetPublishedStoriesCount = <
    TData = Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCount>>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCount>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStatisticsControllerGetPublishedStoriesCountQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCount>>> = ({signal}) =>
        statisticsControllerGetPublishedStoriesCount(signal);

    const query = useQuery<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCount>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const statisticsControllerGetPublishedStoriesCountByPeriod = (
    params: StatisticsControllerGetPublishedStoriesCountByPeriodParams,
    signal?: AbortSignal
) => {
    return axiosInstance<Number>({url: `/public/statistics/published-stories-count-by-period`, method: 'get', params, signal});
};

export const getStatisticsControllerGetPublishedStoriesCountByPeriodQueryKey = (
    params: StatisticsControllerGetPublishedStoriesCountByPeriodParams
) => [`/public/statistics/published-stories-count-by-period`, ...(params ? [params] : [])];

export type StatisticsControllerGetPublishedStoriesCountByPeriodQueryResult = NonNullable<
    Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCountByPeriod>>
>;
export type StatisticsControllerGetPublishedStoriesCountByPeriodQueryError = unknown;

export const useStatisticsControllerGetPublishedStoriesCountByPeriod = <
    TData = Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCountByPeriod>>,
    TError = unknown
>(
    params: StatisticsControllerGetPublishedStoriesCountByPeriodParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCountByPeriod>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStatisticsControllerGetPublishedStoriesCountByPeriodQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCountByPeriod>>> = ({signal}) =>
        statisticsControllerGetPublishedStoriesCountByPeriod(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof statisticsControllerGetPublishedStoriesCountByPeriod>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const statisticsControllerGetManagerMainStatistic = (params: StatisticsControllerGetManagerMainStatisticParams, signal?: AbortSignal) => {
    return axiosInstance<GetManagerMainStatisticResponse[]>({url: `/public/statistics/manager-main-statistic`, method: 'get', params, signal});
};

export const getStatisticsControllerGetManagerMainStatisticQueryKey = (params: StatisticsControllerGetManagerMainStatisticParams) => [
    `/public/statistics/manager-main-statistic`,
    ...(params ? [params] : [])
];

export type StatisticsControllerGetManagerMainStatisticQueryResult = NonNullable<
    Awaited<ReturnType<typeof statisticsControllerGetManagerMainStatistic>>
>;
export type StatisticsControllerGetManagerMainStatisticQueryError = unknown;

export const useStatisticsControllerGetManagerMainStatistic = <
    TData = Awaited<ReturnType<typeof statisticsControllerGetManagerMainStatistic>>,
    TError = unknown
>(
    params: StatisticsControllerGetManagerMainStatisticParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof statisticsControllerGetManagerMainStatistic>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStatisticsControllerGetManagerMainStatisticQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof statisticsControllerGetManagerMainStatistic>>> = ({signal}) =>
        statisticsControllerGetManagerMainStatistic(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof statisticsControllerGetManagerMainStatistic>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const statisticsControllerGetManagerAuthorsStatistic = (
    params: StatisticsControllerGetManagerAuthorsStatisticParams,
    signal?: AbortSignal
) => {
    return axiosInstance<GetManagerAuthorsStatisticResponse[]>({url: `/public/statistics/manager-authors-statistic`, method: 'get', params, signal});
};

export const getStatisticsControllerGetManagerAuthorsStatisticQueryKey = (params: StatisticsControllerGetManagerAuthorsStatisticParams) => [
    `/public/statistics/manager-authors-statistic`,
    ...(params ? [params] : [])
];

export type StatisticsControllerGetManagerAuthorsStatisticQueryResult = NonNullable<
    Awaited<ReturnType<typeof statisticsControllerGetManagerAuthorsStatistic>>
>;
export type StatisticsControllerGetManagerAuthorsStatisticQueryError = unknown;

export const useStatisticsControllerGetManagerAuthorsStatistic = <
    TData = Awaited<ReturnType<typeof statisticsControllerGetManagerAuthorsStatistic>>,
    TError = unknown
>(
    params: StatisticsControllerGetManagerAuthorsStatisticParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof statisticsControllerGetManagerAuthorsStatistic>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStatisticsControllerGetManagerAuthorsStatisticQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof statisticsControllerGetManagerAuthorsStatistic>>> = ({signal}) =>
        statisticsControllerGetManagerAuthorsStatistic(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof statisticsControllerGetManagerAuthorsStatistic>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const statisticsControllerGetManagerGeneralStatistic = (signal?: AbortSignal) => {
    return axiosInstance<GetManagerGeneralStatisticResponse>({url: `/public/statistics/manager-general-statistic`, method: 'get', signal});
};

export const getStatisticsControllerGetManagerGeneralStatisticQueryKey = () => [`/public/statistics/manager-general-statistic`];

export type StatisticsControllerGetManagerGeneralStatisticQueryResult = NonNullable<
    Awaited<ReturnType<typeof statisticsControllerGetManagerGeneralStatistic>>
>;
export type StatisticsControllerGetManagerGeneralStatisticQueryError = unknown;

export const useStatisticsControllerGetManagerGeneralStatistic = <
    TData = Awaited<ReturnType<typeof statisticsControllerGetManagerGeneralStatistic>>,
    TError = unknown
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof statisticsControllerGetManagerGeneralStatistic>>, TError, TData>;
}): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getStatisticsControllerGetManagerGeneralStatisticQueryKey();

    const queryFn: QueryFunction<Awaited<ReturnType<typeof statisticsControllerGetManagerGeneralStatistic>>> = ({signal}) =>
        statisticsControllerGetManagerGeneralStatistic(signal);

    const query = useQuery<Awaited<ReturnType<typeof statisticsControllerGetManagerGeneralStatistic>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
