/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {PaginatedDivision, DivisionControllerFindAllParams, Division} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const divisionControllerFindAll = (params?: DivisionControllerFindAllParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedDivision>({url: `/public/division`, method: 'get', params, signal});
};

export const getDivisionControllerFindAllQueryKey = (params?: DivisionControllerFindAllParams) => [`/public/division`, ...(params ? [params] : [])];

export type DivisionControllerFindAllQueryResult = NonNullable<Awaited<ReturnType<typeof divisionControllerFindAll>>>;
export type DivisionControllerFindAllQueryError = unknown;

export const useDivisionControllerFindAll = <TData = Awaited<ReturnType<typeof divisionControllerFindAll>>, TError = unknown>(
    params?: DivisionControllerFindAllParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof divisionControllerFindAll>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getDivisionControllerFindAllQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof divisionControllerFindAll>>> = ({signal}) => divisionControllerFindAll(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof divisionControllerFindAll>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const divisionControllerFindOne = (id: string, signal?: AbortSignal) => {
    return axiosInstance<Division>({url: `/public/division/${id}`, method: 'get', signal});
};

export const getDivisionControllerFindOneQueryKey = (id: string) => [`/public/division/${id}`];

export type DivisionControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof divisionControllerFindOne>>>;
export type DivisionControllerFindOneQueryError = unknown;

export const useDivisionControllerFindOne = <TData = Awaited<ReturnType<typeof divisionControllerFindOne>>, TError = unknown>(
    id: string,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof divisionControllerFindOne>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getDivisionControllerFindOneQueryKey(id);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof divisionControllerFindOne>>> = ({signal}) => divisionControllerFindOne(id, signal);

    const query = useQuery<Awaited<ReturnType<typeof divisionControllerFindOne>>, TError, TData>({
        queryKey,
        queryFn,
        enabled: !!id,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
