import React, {useEffect, useRef, useState} from 'react';
import {Box, Card, Container, Grid, Stack, Typography} from '@mui/material';
import {Message as MessageIcon} from '@mui/icons-material';
import * as WebDataRocksReact from '@webdatarocks/react-webdatarocks';
import '@webdatarocks/webdatarocks/webdatarocks.css';
import moment from 'moment';
import {Form, FormDatePicker} from 'components/form';
import {HeaderSearchAutocomplete} from 'components/shared/HeaderSearch/HeaderSearchAutocomplete';
import {districtControllerFindAll, getDistrictControllerFindAllQueryKey} from 'api/district';
import {departmentControllerFindAllUnique, getDepartmentControllerFindAllUniqueQueryKey} from 'api/department';
import {useForm} from 'react-hook-form';
import {
    useStatisticsControllerGetManagerAuthorsStatistic,
    useStatisticsControllerGetManagerGeneralStatistic,
    useStatisticsControllerGetManagerMainStatistic
} from 'api/statistics';
import {CircularLoading} from 'components';
import {getAuthorReport} from './getAuthorReport';
import {getMainReport} from './getMainReport';
import {useAuthContext} from 'context';
import {LoadingButton} from '@mui/lab';

export const Statistics = () => {
    const form = useForm({
        defaultValues: {
            date: [moment().subtract(1, 'month').toDate(), moment().toDate()],
            department: null,
            district: null
        }
    });
    const {me} = useAuthContext();
    const hasPrivilege = me?.isDistrictManager || me?.isDepartmentManager || me?.isDivisionManager;
    const mainReportRef = useRef<WebDataRocksReact.Pivot>();
    const authorReportRef = useRef<WebDataRocksReact.Pivot>();
    const [filters, setFilters] = useState<any>(form.getValues());
    const {data: stats, isLoading: isStatsLoading} = useStatisticsControllerGetManagerGeneralStatistic({query: {enabled: hasPrivilege}});
    const {data: mainReport = [], isLoading: isMainReportLoading} = useStatisticsControllerGetManagerMainStatistic(
        {
            'filter[dateFrom]': moment(filters.date[0]).format('YYYY-MM-DD'),
            'filter[dateTo]': moment(filters.date[1]).format('YYYY-MM-DD'),
            'filter[departmentId]': filters.department?.id,
            'filter[districtId]': filters.district?.id
        },
        {query: {enabled: hasPrivilege}}
    );
    const {data: authorReport = [], isLoading: isAuthorReportLoading} = useStatisticsControllerGetManagerAuthorsStatistic(
        {
            'filter[dateFrom]': moment(filters.date[0]).format('YYYY-MM-DD'),
            'filter[dateTo]': moment(filters.date[1]).format('YYYY-MM-DD'),
            'filter[departmentId]': filters.department?.id,
            'filter[districtId]': filters.district?.id
        },
        {query: {enabled: hasPrivilege}}
    );

    const customizeCell = (cell: any, data: any) => {
        if (data?.type === 'header' && data?.hierarchy?.uniqueName === 'author') {
            if (authorReport?.find((d: any) => d.author === data.member?.caption)?.is_blocked) {
                cell.addClass('wdr-cell-is-blocked');
            }
        } else if (data?.type === 'value') {
            const author = data?.rows?.find((r: any) => r?.hierarchyUniqueName === 'author');
            if (author && authorReport?.find((d: any) => d.author === author.caption)?.is_blocked) {
                cell.addClass('wdr-cell-is-blocked');
            }
        }
    };

    useEffect(() => {
        if (mainReport.length) {
            mainReportRef.current?.webdatarocks.setReport(getMainReport(mainReport, me?.isDepartmentManager, me?.isDistrictManager));
        } else {
            mainReportRef.current?.webdatarocks.clear();
        }
    }, [mainReport]);

    useEffect(() => {
        if (authorReport.length) {
            authorReportRef.current?.webdatarocks.setReport(getAuthorReport(authorReport, me?.isDistrictManager, me?.isDivisionManager));
            authorReportRef.current?.webdatarocks.customizeCell(customizeCell);
        } else {
            authorReportRef.current?.webdatarocks.clear();
        }
    }, [authorReport]);

    const onSubmit = (data: any) => {
        setFilters(data);
    };

    if (!hasPrivilege) {
        return (
            <Container>
                <Typography mt={6}>У вас нет доступа на просмотр данной страницы</Typography>
            </Container>
        );
    }

    return (
        <>
            <Container>
                <Box sx={{display: 'flex', mt: 7.5, mb: 4, flexWrap: 'wrap'}}>
                    <Typography variant='h1' color='secondary'>
                        Отчетность&nbsp;
                    </Typography>
                    <Typography variant='h1' color='primary'>
                        руководителя
                    </Typography>
                </Box>
                <Card sx={{p: 2}}>
                    <Stack direction='row' gap={3}>
                        <MessageIcon color='secondary' />
                        <Stack>
                            <Typography variant='body2' lineHeight={1.5}>
                                На данной странице представлена <b>статистика результатов прохождения модерации историй</b>, направленных сотрудниками
                                центров.
                            </Typography>
                            <Typography variant='body2' lineHeight={1.5}>
                                <b>Процент опубликованных историй поможет</b> Вам{' '}
                                <b>определить, кто из сотрудников действительно понимает, что является примером искреннего сервиса</b>, а кто путает
                                его с добрым делом.
                            </Typography>
                            <Typography variant='body2' lineHeight={1.5}>
                                Если у сотрудника много отклоненных историй{' '}
                                <b>рекомендуется провести дополнительный диалог чтобы помочь разобраться.</b> Для этого <b>можно использовать</b>{' '}
                                такие <b>инструменты</b>, как <b>тет-а-тет беседа</b> или <b>утренняя встреча</b>.
                            </Typography>
                        </Stack>
                    </Stack>
                </Card>
                <Box mt={3} mb={2}>
                    <CircularLoading isLoading={isStatsLoading}>
                        <Stack direction='row' width='100%' justifyContent='space-between'>
                            <Stack direction='row' alignItems='center' gap={1}>
                                <Typography variant='h3'>Город:</Typography>
                                <Typography variant='h1' color='primary'>
                                    {parseFloat(stats?.cityPercent || '0') * 100 + '%'}
                                </Typography>
                            </Stack>
                            <Stack direction='row' alignItems='center' gap={1}>
                                <Typography variant='h3'>Дивизион:</Typography>
                                <Typography variant='h1' color='primary'>
                                    {parseFloat(stats?.divisionPercent || '0') * 100 + '%'}
                                </Typography>
                            </Stack>
                            <Stack direction='row' alignItems='center' gap={1}>
                                <Typography variant='h3'>Округ:</Typography>
                                <Typography variant='h1' color='primary'>
                                    {parseFloat(stats?.districtPercent || '0') * 100 + '%'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </CircularLoading>
                </Box>
                <Form form={form} onSubmit={onSubmit}>
                    <Grid container spacing={2.5}>
                        <Grid item xs={3}>
                            <FormDatePicker size='small' name='date' placeholder='Выберите даты' color='secondary' noParams />
                        </Grid>
                        {me?.isDivisionManager && (
                            <Grid item xs={3}>
                                <HeaderSearchAutocomplete
                                    name='district'
                                    optionText='name'
                                    queryProps={{
                                        query: districtControllerFindAll,
                                        queryKey: getDistrictControllerFindAllQueryKey
                                    }}
                                    placeholder='Выберите округ'
                                />
                            </Grid>
                        )}
                        {(me?.isDistrictManager || me?.isDivisionManager) && (
                            <Grid item xs={3}>
                                <HeaderSearchAutocomplete
                                    name='department'
                                    optionText='abbreviation'
                                    queryProps={{
                                        query: departmentControllerFindAllUnique,
                                        queryKey: getDepartmentControllerFindAllUniqueQueryKey
                                    }}
                                    placeholder='Выберите подразделение'
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={3} md={4} lg={1}>
                            <LoadingButton
                                loading={isMainReportLoading || isAuthorReportLoading}
                                fullWidth
                                variant='contained'
                                color='neutral'
                                type='submit'
                                sx={{
                                    height: 43
                                }}>
                                Найти
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            </Container>

            <Stack
                gap={3}
                mt={4}
                px={4}
                sx={{
                    '.wdr-credits': {display: 'none !important'},
                    '.wdr-grid-layout #wdr-data-sheet .wdr-cell': {textAlign: 'center !important'}
                }}>
                <Box>
                    <WebDataRocksReact.Pivot ref={mainReportRef} height={me?.isDepartmentManager ? 200 : 500} />
                </Box>
                <Box sx={(theme) => ({'.wdr-cell-is-blocked': {color: theme.palette.neutral.main + '!important'}})}>
                    <WebDataRocksReact.Pivot ref={authorReportRef} height={800} customizeCell={customizeCell} />
                </Box>
            </Stack>
        </>
    );
};
