import React, {useState} from 'react';
import {AppBar, Box, Button, Collapse, Container, IconButton, Stack, styled, Theme, Toolbar, Typography, useMediaQuery} from '@mui/material';
import {DocumentIcon, GearIcon, HeartIcon, HomeIcon, StarFilledIcon} from 'assets/icons';
import {Link} from 'react-router-dom';
import {useAuthContext, useThemeContext} from 'context';
import {MODE} from 'theme';
import {ROUTES} from 'utils';
import {DarkMode as DarkModeIcon, LightMode as LightModeIcon, Menu as MenuIcon, ArticleOutlined as ArticleIcon} from '@mui/icons-material';
import {Notifications} from '../../shared';
import {useModal} from 'hooks';
import FeedbackModal from './FeedbackModal';

const StyledHomeIcon = styled(HomeIcon)(({theme}) => ({
    path: {
        fill: themes[theme.palette.mode].homeFill
    }
}));
const NAV_LIST = [
    {
        icon: <StyledHomeIcon />,
        title: 'Главная',
        to: ROUTES.BASE
    },
    {
        icon: <HeartIcon />,
        title: 'Вам понравилось',
        to: ROUTES.LIKED
    },
    {
        icon: <StarFilledIcon />,
        title: 'Избранное',
        to: ROUTES.FAVORITE
    },
    {
        icon: <GearIcon />,
        title: 'Рейтинги',
        to: ROUTES.RATING
    },
    {
        icon: <ArticleIcon />,
        title: 'Отчетность',
        to: ROUTES.STATISTICS
    }
];

const Navbar = () => {
    const {me} = useAuthContext();
    const {theme, saveTheme} = useThemeContext();
    const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const [open, setOpen] = useState(false);
    const {open: openFeedback, handleToggle: handleToggleFeedBack} = useModal();

    const menu = (
        <>
            <Box mt={{xs: 2, md: 0}} mb={{xs: 1, md: 0}}>
                <Link to='/profile'>
                    <Typography variant='body2' fontWeight={600} color={location.pathname === ROUTES.PROFILE ? 'primary.main' : 'inherit'}>
                        Мой профиль
                    </Typography>
                </Link>
            </Box>
            <Stack direction={{md: 'row'}}>
                {NAV_LIST.filter(
                    (i) => !(i.to === ROUTES.STATISTICS && !(me?.isDepartmentManager || me?.isDivisionManager || me?.isDistrictManager))
                ).map((item, index) => (
                    <StyledNavLink key={index} to={item.to}>
                        {item.icon}
                        <Typography variant='body2' ml={1} color={location.pathname === item.to ? 'primary' : 'inherit'}>
                            {item.title}
                        </Typography>
                    </StyledNavLink>
                ))}
                <Button variant='contained' color='secondary' sx={{my: {xs: 1}, mx: {lg: 3}, py: 1}} onClick={handleToggleFeedBack}>
                    Обратная связь
                </Button>
            </Stack>
            <Box color='#D3D2D2' display='flex'>
                <IconButton onClick={() => saveTheme(theme === MODE.DARK ? MODE.LIGHT : MODE.DARK)} color='inherit'>
                    {theme === MODE.LIGHT ? <DarkModeIcon /> : <LightModeIcon />}
                </IconButton>
                <Notifications isNavigation={true} />
                <IconButton component={Link} to={ROUTES.USER_AGREEMENT}>
                    <DocumentIcon />
                </IconButton>
            </Box>
            <FeedbackModal open={openFeedback} handleToggle={() => handleToggleFeedBack()} />
        </>
    );

    return (
        <>
            <StyledAppBar position='relative' sx={{margin: 0}} aria-controls='menu-notifications'>
                <Container>
                    <Toolbar
                        disableGutters
                        sx={{
                            minHeight: {xs: 48, md: 64},
                            py: {xs: 1.5, md: 0},
                            justifyContent: {xs: 'center', md: 'space-between'},
                            alignItems: {xs: 'flex-start', md: 'center'},
                            flexDirection: {xs: 'column', md: 'row'}
                        }}>
                        <StyledToggle display={{md: 'none'}} onClick={() => setOpen((prevState) => !prevState)}>
                            <MenuIcon />
                        </StyledToggle>
                        {isMd ? menu : <Collapse in={open}>{menu}</Collapse>}
                    </Toolbar>
                </Container>
            </StyledAppBar>
        </>
    );
};

export default Navbar;

const themes = {
    [MODE.LIGHT]: {
        navbarShadow: '0px 4px 10px rgba(80, 37, 22, 0.4)',
        homeFill: '#623B2A'
    },
    [MODE.DARK]: {
        navbarShadow: '0px 4px 10px rgba(73, 73, 73, 0.4)',
        homeFill: '#767575'
    }
};

const StyledAppBar = styled(AppBar)(({theme}) => ({
    backgroundImage: 'none',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: themes[theme.palette.mode].navbarShadow
}));

const StyledNavLink = styled(Link)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 3)
    },
    [theme.breakpoints.down('md')]: {
        margin: theme.spacing(1, 0)
    }
}));

const StyledToggle = styled(Box)`
    border: 1px solid #e04e39;
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 5px;

    svg {
        fill: #e04e39;
    }

    &:focus {
        box-shadow: none;
    }
`;
