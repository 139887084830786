export const getAuthorReport = (data: any, isDistrictManager?: boolean, isDivisionManager?: boolean) => ({
    dataSource: {
        dataSourceType: 'json',
        data: data
    },
    localization: 'https://cdn.webdatarocks.com/loc/ru.json',
    slice: {
        rows: [
            {
                ...(isDivisionManager && {
                    uniqueName: 'name',
                    caption: 'Округ'
                })
            },
            {
                ...((isDistrictManager || isDivisionManager) && {
                    uniqueName: 'department',
                    caption: 'Подразделение'
                })
            },
            {
                uniqueName: 'author',
                caption: 'Автор'
            }
        ],
        columns: [
            {
                uniqueName: 'Measures'
            }
        ],
        measures: [
            {
                "uniqueName": "calc_percent",
                "formula": "if(sum(\"sended\") == 0, 0, sum(\"published\") / sum(\"sended\"))",
                "caption": "% опубликованных",
                "format": "percent_style"
            },
            // {
            //     uniqueName: 'percent',
            //     caption: '% опубликованный историй',
            //     aggregation: 'average',
            //     format: 'percent_style'
            // },
            {
                uniqueName: 'sended',
                caption: 'Направленных',
                aggregation: 'sum'
            },
            {
                uniqueName: 'published',
                caption: 'Опубликованных',
                aggregation: 'sum'
            },
            {
                uniqueName: 'not_published',
                caption: 'Неопубликованных',
                aggregation: 'sum'
            },
            {
                uniqueName: 'under_moderation',
                caption: 'Историй на модерации',
                aggregation: 'sum'
            },
            {
                uniqueName: 'pinned',
                caption: 'Закрепленных',
                aggregation: 'sum'
            },
            {
                uniqueName: 'views',
                caption: 'Просмотров',
                aggregation: 'sum'
            },
            {
                uniqueName: 'likes',
                caption: 'Лайков',
                aggregation: 'sum'
            }
        ],
        expands: {
            expandAll: !(isDistrictManager || isDivisionManager)
        }
    },
    options: {
        grid: {
            // type: 'classic',
            showHeaders: false
        },
        showAggregationLabels: false,
        configuratorButton: false
    },
    formats: [
        {
            name: 'percent_style',
            thousandsSeparator: ' ',
            decimalSeparator: '.',
            maxDecimalPlaces: 2,
            currencySymbol: '',
            currencySymbolAlign: 'left',
            nullValue: '',
            textAlign: 'right',
            isPercent: true
        }
    ]
});
