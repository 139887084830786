/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
export type UserRequestControllerFindAllParams = {
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type UserControllerFindAllUserStoriesFilterIsDraft =
    (typeof UserControllerFindAllUserStoriesFilterIsDraft)[keyof typeof UserControllerFindAllUserStoriesFilterIsDraft];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserControllerFindAllUserStoriesFilterIsDraft = {
    true: 'true',
    false: 'false'
} as const;

export type UserControllerFindAllUserStoriesParams = {
    'filter[isDraft]'?: UserControllerFindAllUserStoriesFilterIsDraft;
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type UserControllerGetListParams = {
    'filter[query]'?: string;
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type TerritorialUnitControllerFindAllParams = {
    'filter[name]'?: string;
    'filter[divisionId]'?: number;
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type TagControllerFindAllParams = {
    'filter[name]'?: string;
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type StoryControllerGetStoryCommentsParams = {
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type StoryControllerFindAllOrderDirection = (typeof StoryControllerFindAllOrderDirection)[keyof typeof StoryControllerFindAllOrderDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoryControllerFindAllOrderDirection = {
    ASC: 'ASC',
    DESC: 'DESC'
} as const;

export type StoryControllerFindAllOrderBy = (typeof StoryControllerFindAllOrderBy)[keyof typeof StoryControllerFindAllOrderBy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoryControllerFindAllOrderBy = {
    created_at: 'created_at',
    published_at: 'published_at',
    like: 'like'
} as const;

export type StoryControllerFindAllFilterIsSelf = (typeof StoryControllerFindAllFilterIsSelf)[keyof typeof StoryControllerFindAllFilterIsSelf];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoryControllerFindAllFilterIsSelf = {
    true: 'true',
    false: 'false'
} as const;

export type StoryControllerFindAllFilterIsLiked = (typeof StoryControllerFindAllFilterIsLiked)[keyof typeof StoryControllerFindAllFilterIsLiked];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoryControllerFindAllFilterIsLiked = {
    true: 'true',
    false: 'false'
} as const;

export type StoryControllerFindAllFilterIsFavorite =
    (typeof StoryControllerFindAllFilterIsFavorite)[keyof typeof StoryControllerFindAllFilterIsFavorite];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoryControllerFindAllFilterIsFavorite = {
    true: 'true',
    false: 'false'
} as const;

export type StoryControllerFindAllFilterIsViewed = (typeof StoryControllerFindAllFilterIsViewed)[keyof typeof StoryControllerFindAllFilterIsViewed];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StoryControllerFindAllFilterIsViewed = {
    true: 'true',
    false: 'false'
} as const;

export type StoryControllerFindAllParams = {
    'filter[search]'?: string;
    'filter[dateFrom]'?: string;
    'filter[dateTo]'?: string;
    'filter[departmentAbbreviation]'?: string;
    'filter[tagIds]'?: number[];
    'filter[isViewed]'?: StoryControllerFindAllFilterIsViewed;
    'filter[isFavorite]'?: StoryControllerFindAllFilterIsFavorite;
    'filter[isLiked]'?: StoryControllerFindAllFilterIsLiked;
    'filter[isSelf]'?: StoryControllerFindAllFilterIsSelf;
    'order[by]'?: StoryControllerFindAllOrderBy;
    'order[direction]'?: StoryControllerFindAllOrderDirection;
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type StoryAuthorControllerFindAllWithStoryLikesCountParams = {
    'filter[dateFrom]'?: string;
    'filter[dateTo]'?: string;
    'filter[districtId]'?: number;
    'filter[divisionId]'?: number;
    'filter[territorialUnitId]'?: number;
    'filter[departmentId]'?: number;
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type StoryAuthorControllerFindAllWithStoriesCountParams = {
    'filter[dateFrom]'?: string;
    'filter[dateTo]'?: string;
    'filter[districtId]'?: number;
    'filter[divisionId]'?: number;
    'filter[territorialUnitId]'?: number;
    'filter[departmentId]'?: number;
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type StatisticsControllerGetManagerAuthorsStatisticParams = {
    'filter[dateFrom]': string;
    'filter[dateTo]': string;
    'filter[departmentId]'?: string;
    'filter[districtId]'?: string;
};

export type StatisticsControllerGetManagerMainStatisticParams = {
    'filter[dateFrom]': string;
    'filter[dateTo]': string;
    'filter[departmentId]'?: string;
    'filter[districtId]'?: string;
};

export type StatisticsControllerGetPublishedStoriesCountByPeriodParams = {
    'filter[dateFrom]': string;
    'filter[dateTo]': string;
};

export type NotificationControllerGetAllFilterByView =
    (typeof NotificationControllerGetAllFilterByView)[keyof typeof NotificationControllerGetAllFilterByView];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationControllerGetAllFilterByView = {
    ALL: 'ALL',
    VIEWED: 'VIEWED',
    UNVIEWED: 'UNVIEWED'
} as const;

export type NotificationControllerGetAllParams = {
    'filter[byView]'?: NotificationControllerGetAllFilterByView;
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type MediaControllerFindAllParams = {
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type DivisionControllerFindAllFilterIsManager =
    (typeof DivisionControllerFindAllFilterIsManager)[keyof typeof DivisionControllerFindAllFilterIsManager];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DivisionControllerFindAllFilterIsManager = {
    true: 'true',
    false: 'false'
} as const;

export type DivisionControllerFindAllParams = {
    'filter[name]'?: string;
    'filter[isManager]'?: DivisionControllerFindAllFilterIsManager;
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type DistrictControllerFindAllFilterIsManager =
    (typeof DistrictControllerFindAllFilterIsManager)[keyof typeof DistrictControllerFindAllFilterIsManager];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DistrictControllerFindAllFilterIsManager = {
    true: 'true',
    false: 'false'
} as const;

export type DistrictControllerFindAllParams = {
    'filter[name]'?: string;
    'filter[isManager]'?: DistrictControllerFindAllFilterIsManager;
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export type DepartmentControllerFindAllUniqueFilterIsManager =
    (typeof DepartmentControllerFindAllUniqueFilterIsManager)[keyof typeof DepartmentControllerFindAllUniqueFilterIsManager];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DepartmentControllerFindAllUniqueFilterIsManager = {
    true: 'true',
    false: 'false'
} as const;

export type DepartmentControllerFindAllUniqueParams = {
    'filter[search]'?: string;
    'filter[districtId]'?: number;
    'filter[divisionId]'?: number;
    'filter[territorialUnitId]'?: number;
    'filter[isManager]'?: DepartmentControllerFindAllUniqueFilterIsManager;
    'pagination[skip]'?: number;
    'pagination[take]'?: number;
};

export interface PaginatedOrganization {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: Organization[];
}

export interface PaginatedRejectionReason {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: RejectionReason[];
}

export interface PaginatedStory {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: Story[];
}

export interface PaginatedJobPost {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: JobPost[];
}

export interface PaginatedComment {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: Comment[];
}

export interface RefreshToken {
    id: number;
    createdAt: string;
    updatedAt: string;
}

export interface PaginatedUserRequest {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: UserRequest[];
}

export interface CreateUserRequestInput {
    title: string;
    content: string;
    uploadedFile?: Blob;
}

export interface BaseResponseType {
    statusCode: number;
    message: string;
}

export interface FeedbackInput {
    title: string;
    description: string;
    uploadedFile?: Blob;
}

export interface CreateVisitDto {
    link: string;
    browser: string;
}

export interface Object {
    [key: string]: any;
}

export interface PaginatedUser {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: User[];
}

export interface UpdateUserAvatarInput {
    fileId?: number;
    uploadedFile?: Blob;
}

export interface PaginatedTerritorialUnit {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: TerritorialUnit[];
}

export interface PaginatedTag {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: Tag[];
}

export interface CreateStoryViewDto {
    storyId: number;
}

export interface CreateStoryFavoriteDto {
    storyId: number;
}

export interface PublicCommentDto {
    id: number;
    createdAt: string;
    updatedAt: string;
    text: string;
    author: User;
    authorId: number;
    story: Story;
    storyId: number;
    likes: Like[];
    likesCount: number;
    isLiked: boolean;
}

export interface PaginatedPublicCommentDto {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: PublicCommentDto[];
}

export interface PublicOneStoryDto {
    id: number;
    createdAt: string;
    updatedAt: string;
    title: string;
    description: string;
    status: string;
    publishedAt: string;
    isPinned: boolean;
    pinnedTo: string;
    availableOrganizationIds: string[];
    file: File;
    fileId: number;
    videoFile: File;
    videoFileId: number;
    department: Department;
    departmentId: number;
    rejectionReason: RejectionReason;
    rejectionReasonId: number;
    authors: StoryAuthor[];
    viewers: StoryView[];
    usersAddedFavorite: UserFavoriteStory[];
    comments: Comment[];
    tags: StoryTag[];
    likes: Like[];
    mentions: UserMention[];
    logs: Comment[];
    likesCount: number;
    viewsCount: number;
    commentsCount: number;
    isViewed: boolean;
    isFavorite: boolean;
    isLiked: boolean;
    firstsViewers: StoryView[];
}

export interface PaginatedPublicStoryDto {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: PublicStoryDto[];
}

export interface CreateCommentInput {
    text: string;
}

export type UpdateDraftDtoIsDraft = (typeof UpdateDraftDtoIsDraft)[keyof typeof UpdateDraftDtoIsDraft];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateDraftDtoIsDraft = {
    true: 'true',
    false: 'false'
} as const;

export interface UpdateDraftDto {
    title: string;
    description: string;
    fileId?: number;
    uploadedFile?: Blob;
    videoFileId?: number;
    uploadedVideoFile?: Blob;
    tagIds?: string[];
    isDraft?: UpdateDraftDtoIsDraft;
}

export type CreateStoryPublicDtoIsDraft = (typeof CreateStoryPublicDtoIsDraft)[keyof typeof CreateStoryPublicDtoIsDraft];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateStoryPublicDtoIsDraft = {
    true: 'true',
    false: 'false'
} as const;

export interface CreateStoryPublicDto {
    title: string;
    description: string;
    fileId?: number;
    uploadedFile?: Blob;
    videoFileId?: number;
    uploadedVideoFile?: Blob;
    tagIds?: string[];
    isDraft?: CreateStoryPublicDtoIsDraft;
}

export interface StoryAuthorWithStoryLikesCountDto {
    userId: number;
    firstName: string;
    lastName: string;
    likesCount: number;
}

export interface PaginatedStoryAuthorWithStoryLikesCount {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: StoryAuthorWithStoryLikesCountDto[];
}

export interface StoryAuthorWithStoriesCountDto {
    userId: number;
    firstName: string;
    lastName: string;
    storiesCount: number;
}

export interface PaginatedStoryAuthorWithStoriesCount {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: StoryAuthorWithStoriesCountDto[];
}

export interface GetManagerGeneralStatisticResponse {
    cityPercent: string;
    districtPercent: string;
    divisionPercent: string;
}

export interface GetManagerAuthorsStatisticResponse {
    percent: string;
    author: string;
    is_blocked: string;
    name: string;
    department: string;
    sended: string;
    published: string;
    not_published: string;
    under_moderation: string;
    pinned: string;
    views: string;
    likes: string;
}

export interface GetManagerMainStatisticResponse {
    percent: string;
    name: string;
    sended: string;
    published: string;
    not_published: string;
    under_moderation: string;
    pinned: string;
    views: string;
    likes: string;
}

export interface Number {
    [key: string]: any;
}

export interface PaginatedNotification {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: Notification[];
}

export interface ViewNotificationInput {
    notificationId: number;
}

export interface Media {
    id: number;
    createdAt: string;
    updatedAt: string;
    title: string;
    description: string;
    file: File;
    fileId: number;
}

export interface PaginatedMedia {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: Media[];
}

export type CreateOrRemoveLikeDtoType = (typeof CreateOrRemoveLikeDtoType)[keyof typeof CreateOrRemoveLikeDtoType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateOrRemoveLikeDtoType = {
    STORY: 'STORY',
    COMMENT: 'COMMENT'
} as const;

export interface CreateOrRemoveLikeDto {
    id: number;
    type: CreateOrRemoveLikeDtoType;
}

export interface PaginatedDivision {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: Division[];
}

export interface DistrictManager {
    id: number;
    createdAt: string;
    updatedAt: string;
    district: District;
    districtId: number;
    user: User;
    userId: number;
}

export interface District {
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    departments: Department[];
    managers: DistrictManager[];
}

export interface PaginatedDistrict {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: District[];
}

export interface TerritorialUnit {
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    division: Division;
    divisionId: number;
    departments: Department[];
}

export interface Department {
    id: number;
    createdAt: string;
    updatedAt: string;
    MO: string;
    abbreviation: string;
    fullName: string;
    address: string;
    hasOrder: number;
    organization: Organization;
    organizationId: number;
    district: District;
    districtId: number;
    territorialUnit: TerritorialUnit;
    territorialUnitId: number;
    users: User[];
    mainUsers: User[];
    stories: Story[];
    managers: DepartmentManager[];
}

export interface PublicStoryDto {
    id: number;
    createdAt: string;
    updatedAt: string;
    title: string;
    description: string;
    status: string;
    publishedAt: string;
    isPinned: boolean;
    pinnedTo: string;
    availableOrganizationIds: string[];
    file: File;
    fileId: number;
    videoFile: File;
    videoFileId: number;
    department: Department;
    departmentId: number;
    rejectionReason: RejectionReason;
    rejectionReasonId: number;
    authors: StoryAuthor[];
    viewers: StoryView[];
    usersAddedFavorite: UserFavoriteStory[];
    comments: Comment[];
    tags: StoryTag[];
    likes: Like[];
    mentions: UserMention[];
    logs: Comment[];
    likesCount: number;
    viewsCount: number;
    commentsCount: number;
    isViewed: boolean;
    isFavorite: boolean;
    isLiked: boolean;
}

export interface PaginatedDepartment {
    take: number;
    skip: number;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    data: Department[];
}

export interface Division {
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    territorialUnits: TerritorialUnit[];
    managers: DivisionManager[];
}

export interface DivisionManager {
    id: number;
    createdAt: string;
    updatedAt: string;
    division: Division;
    divisionId: number;
    user: User;
    userId: number;
}

export interface DepartmentManager {
    id: number;
    createdAt: string;
    updatedAt: string;
    department: Department;
    departmentId: number;
    user: User;
    userId: number;
}

export interface StoryLog {
    id: number;
    createdAt: string;
    updatedAt: string;
    action: string;
    newStatus: string;
    story: Story;
    storyId: number;
    user: User;
    userId: number;
}

export interface UserLog {
    id: number;
    createdAt: string;
    updatedAt: string;
    action: string;
    user: User;
    userId: number;
    actionUser: User;
    actionUserId: number;
}

export interface UserRequestMessage {
    id: number;
    createdAt: string;
    updatedAt: string;
    content: string;
    type: string;
    request: UserRequest;
    requestId: number;
    sender: User;
    senderId: number;
    file: File;
    fileId: number;
}

export interface UserRequest {
    id: number;
    createdAt: string;
    updatedAt: string;
    title: string;
    status: string;
    user: User;
    userId: number;
    messages?: UserRequestMessage[];
}

export interface Notification {
    id: number;
    createdAt: string;
    updatedAt: string;
    type: string;
    isViewed: boolean;
    user: User;
    userId: number;
    like: Like;
    likeId: number;
    comment: Comment;
    commentId: number;
}

export interface Visit {
    id: number;
    createdAt: string;
    updatedAt: string;
    link: string;
    browser: string;
    device: string;
    user: User;
    userId: number;
}

export interface StoryAuthor {
    id: number;
    createdAt: string;
    updatedAt: string;
    story: Story;
    storyId: number;
    user: User;
    userId: number;
}

export interface UserMention {
    id: number;
    createdAt: string;
    updatedAt: string;
    story: Story;
    storyId: number;
    user: User;
    userId: number;
}

export interface Tag {
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    order: number;
    stories: StoryTag[];
}

export interface StoryTag {
    id: number;
    createdAt: string;
    updatedAt: string;
    story: Story;
    storyId: number;
    tag: Tag;
    tagId: number;
}

export interface Like {
    id: number;
    createdAt: string;
    updatedAt: string;
    user: User;
    userId: number;
    story: Story;
    storyId: number;
    comment: Comment;
    commentId: number;
}

export interface Comment {
    id: number;
    createdAt: string;
    updatedAt: string;
    text: string;
    author: User;
    authorId: number;
    story: Story;
    storyId: number;
    likes: Like[];
}

export interface UserFavoriteStory {
    id: number;
    createdAt: string;
    updatedAt: string;
    story: Story;
    storyId: number;
    user: User;
    userId: number;
}

export interface StoryView {
    id: number;
    createdAt: string;
    updatedAt: string;
    story: Story;
    storyId: number;
    user: User;
    userId: number;
}

export interface RejectionReason {
    id: number;
    createdAt: string;
    updatedAt: string;
    text: string;
    stories: Story[];
}

export interface Story {
    id: number;
    createdAt: string;
    updatedAt: string;
    title: string;
    description: string;
    status: string;
    publishedAt: string;
    isPinned: boolean;
    pinnedTo: string;
    availableOrganizationIds: string[];
    file: File;
    fileId: number;
    videoFile: File;
    videoFileId: number;
    department: Department;
    departmentId: number;
    rejectionReason: RejectionReason;
    rejectionReasonId: number;
    authors: StoryAuthor[];
    viewers: StoryView[];
    usersAddedFavorite: UserFavoriteStory[];
    comments: Comment[];
    tags: StoryTag[];
    likes: Like[];
    mentions: UserMention[];
    logs: Comment[];
}

export interface JobPost {
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    users: User[];
}

export interface File {
    id: number;
    createdAt: string;
    updatedAt: string;
    key: string;
    name: string;
    url: string;
    size: number;
    extension: string;
}

export interface User {
    id: number;
    createdAt: string;
    updatedAt: string;
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    isUserAgreement: boolean;
    isSendAccess: boolean;
    isBlocked: boolean;
    role: string;
    lastOnlineAt: string;
    passwordUpdatedAt: string;
    avatar: File;
    avatarId: number;
    mainDepartment: Department;
    mainDepartmentId: number;
    department: Department;
    departmentId: number;
    jobPost: JobPost;
    jobPostId: number;
    authoredStories: StoryAuthor[];
    viewedStories: StoryView[];
    favoriteStories: UserFavoriteStory[];
    comments: Comment[];
    likes: Like[];
    visits: Visit[];
    notifications: Notification[];
    mentions: UserMention[];
    userRequests: UserRequest[];
    userRequestsMessages: UserRequestMessage[];
    userLogs: UserLog[];
    actionUserLogs: UserLog[];
    storiesLogs: StoryLog[];
    departmentsManagement: DepartmentManager[];
    districtsManagement: DistrictManager[];
    divisionsManagement: DivisionManager[];
}

export interface Organization {
    id: number;
    createdAt: string;
    updatedAt: string;
    name: string;
    departments: Department[];
}
