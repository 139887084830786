import React, {useRef} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Box, InputAdornment, styled, TextField, TextFieldProps} from '@mui/material';
import moment from 'moment';
import {useController, UseControllerProps} from 'react-hook-form';
import {Labeled} from './Labeled';
import {useQsParams} from 'hooks';
import {CalendarIcon} from 'assets/icons';

export const FormDatePicker = (
    props: UseControllerProps &
        Omit<TextFieldProps, 'label'> & {
            label?: string;
            noParams?: boolean;
        }
) => {
    const {name, label, rules, placeholder, noParams, ...rest} = props;
    const {field} = useController({name, rules});
    const [params, setParams] = useQsParams();
    const changePickerRow = (value: string) => {
        if (value) {
            return value.split(' - ').map((el) => {
                const convertDate = moment(el, 'DD.MM.YYYY').format('MM.DD.YYYY');
                const validationDate = moment(convertDate).isValid();
                if (validationDate) {
                    return new Date(convertDate);
                }
            });
        }
    };

    const inputRef = useRef<HTMLInputElement>(null);
    return (
        <Labeled label={label}>
            <DatePickerWrapper>
                <DatePicker
                    {...field}
                    selectsRange
                    startDate={field.value?.[0] || null}
                    endDate={field.value?.[1] || null}
                    placeholderText={placeholder || label}
                    // selected={field.value}
                    onChange={(update) => {
                        if (!noParams && (update[1] || !update[0])) {
                            setParams({...params, ...{[name]: update[1] ? update : ''}});
                        }
                        field.onChange(update.filter((x) => x));
                    }}
                    isClearable
                    customInput={
                        <TextField
                            fullWidth
                            color={props.color || 'primary'}
                            inputProps={{ref: inputRef}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start' sx={{cursor: 'pointer', mr: 2}}>
                                        <CalendarIcon />
                                    </InputAdornment>
                                )
                            }}
                            {...rest}
                        />
                    }
                    dateFormat='dd.MM.yyyy'
                    onChangeRaw={(event) => {
                        field.onChange(changePickerRow(event.target.value));
                        // inputRef?.current && inputRef?.current?.focus();
                    }}
                />
            </DatePickerWrapper>
        </Labeled>
    );
};

const DatePickerWrapper = styled(Box)({
    '& .react-datepicker__close-icon::after': {
        color: 'grey',
        fontSize: '20px',
        fontWeight: 400,
        height: '28px',
        width: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'inherit',
        transition: 'all ease-in-out 0.2s',
        fontFamily: 'math',
        // paddingLeft: '3px',
        // paddingTop: '3px',
        animation: 'background 2s '
    },
    '& .react-datepicker__close-icon:hover::after': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '& .react-datepicker__close-icon:active::after': {
        backgroundColor: 'rgba(0, 0, 0, 0.15)'
    }
});
