/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * MDD Public
 * The MDD NestJS app Public API description
 * OpenAPI spec version: 0.0.1
 */
import {useQuery} from '@tanstack/react-query';
import type {UseQueryOptions, QueryFunction, UseQueryResult, QueryKey} from '@tanstack/react-query';
import type {PaginatedDepartment, DepartmentControllerFindAllUniqueParams, Department} from './mDDPublic.schemas';
import {axiosInstance} from './mutator/axios-instance';

export const departmentControllerFindAllUnique = (params?: DepartmentControllerFindAllUniqueParams, signal?: AbortSignal) => {
    return axiosInstance<PaginatedDepartment>({url: `/public/department`, method: 'get', params, signal});
};

export const getDepartmentControllerFindAllUniqueQueryKey = (params?: DepartmentControllerFindAllUniqueParams) => [
    `/public/department`,
    ...(params ? [params] : [])
];

export type DepartmentControllerFindAllUniqueQueryResult = NonNullable<Awaited<ReturnType<typeof departmentControllerFindAllUnique>>>;
export type DepartmentControllerFindAllUniqueQueryError = unknown;

export const useDepartmentControllerFindAllUnique = <TData = Awaited<ReturnType<typeof departmentControllerFindAllUnique>>, TError = unknown>(
    params?: DepartmentControllerFindAllUniqueParams,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof departmentControllerFindAllUnique>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getDepartmentControllerFindAllUniqueQueryKey(params);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof departmentControllerFindAllUnique>>> = ({signal}) =>
        departmentControllerFindAllUnique(params, signal);

    const query = useQuery<Awaited<ReturnType<typeof departmentControllerFindAllUnique>>, TError, TData>({
        queryKey,
        queryFn,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};

export const departmentControllerFindOne = (id: string, signal?: AbortSignal) => {
    return axiosInstance<Department>({url: `/public/department/${id}`, method: 'get', signal});
};

export const getDepartmentControllerFindOneQueryKey = (id: string) => [`/public/department/${id}`];

export type DepartmentControllerFindOneQueryResult = NonNullable<Awaited<ReturnType<typeof departmentControllerFindOne>>>;
export type DepartmentControllerFindOneQueryError = unknown;

export const useDepartmentControllerFindOne = <TData = Awaited<ReturnType<typeof departmentControllerFindOne>>, TError = unknown>(
    id: string,
    options?: {query?: UseQueryOptions<Awaited<ReturnType<typeof departmentControllerFindOne>>, TError, TData>}
): UseQueryResult<TData, TError> & {queryKey: QueryKey} => {
    const {query: queryOptions} = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getDepartmentControllerFindOneQueryKey(id);

    const queryFn: QueryFunction<Awaited<ReturnType<typeof departmentControllerFindOne>>> = ({signal}) => departmentControllerFindOne(id, signal);

    const query = useQuery<Awaited<ReturnType<typeof departmentControllerFindOne>>, TError, TData>({
        queryKey,
        queryFn,
        enabled: !!id,
        ...queryOptions
    }) as UseQueryResult<TData, TError> & {queryKey: QueryKey};

    query.queryKey = queryKey;

    return query;
};
